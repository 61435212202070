:root { 
--deep_orange_A100:#f19c7f; 
--red_600:#fb2929; 
--red_200:#eda48b; 
--blue_A200:#4285f4; 
--gray_800_01:#3c4043; 
--black_900_01:#0d0d0d; 
--yellow_800:#fbaf29; 
--deep_orange_200_01:#f5b9a5; 
--deep_orange_900:#b83d14; 
--teal_900:#063d32; 
--blue_gray_900_01:#323232; 
--blue_gray_900:#333333; 
--red_A700:#ed0006; 
--black_900_02:#000a19; 
--gray_400:#b3b3b3; 
--blue_gray_100:#d7d9dd; 
--blue_gray_300:#979fab; 
--amber_500:#fbbc04; 
--gray_800:#4d4d4d; 
--blue_gray_500:#6c7788; 
--amber_700:#f9a000; 
--yellow_50:#fff6e7; 
--amber_100:#f8e9b2; 
--gray_200:#e6e8eb; 
--green_600_01:#34a853; 
--white_A700:#ffffff; 
--teal_900_01:#124b4e; 
--indigo_800:#253b80; 
--blue_gray_100_01:#cccccc; 
--yellow_50_01:#fdfaec; 
--amber_500_01:#fbbc05; 
--light_blue_600:#179bd7; 
--red_500:#ea4335; 
--light_blue_400:#1cb7eb; 
--amber_A200:#eec943; 
--green_600:#31b647; 
--gray_50:#f8f9fa; 
--red_100:#fadbd1; 
--deep_orange_A100_01:#f09475; 
--black_900:#000000; 
--blue_gray_100_7f:#d9d9d97f; 
--deep_orange_600:#e75423; 
--light_green_700:#799e29; 
--blue_gray_800:#2c3c55; 
--deep_orange_200:#f5b8a3; 
--deep_orange_400:#eb7047; 
--gray_700:#666666; 
--gray_500:#999999; 
--orange_A700:#ff5e00; 
--gray_900:#011432; 
--gray_900_01:#232323; 
--amber_A200_cc:#eec943cc; 
--gray_300:#e6e6e6; 
--gray_100:#f2f2f2; 
--lime_200_7f:#f1de9b7f; 
--gray_900_7f:#0114327f; 
--white_A700_01:#fdfdfc; 
--indigo_900:#222d65; 
}